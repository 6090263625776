<template>
  <v-flex>
  <SignIn v-if="$route.name == 'Sign In'" />
  <SignUp v-if="$route.name == 'Sign Up'" />
  </v-flex>
</template>

<script>
import SignIn from "../components/SignIn/SignIn";
import SignUp from "../components/SignUp/SignUp";

export default {
  name: 'Public',
  components: {
    SignUp,
    SignIn
  },
};
</script>
