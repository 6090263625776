import Vue from 'vue'
import Router from 'vue-router'
import Public from './views/Public'
import Private from "./views/Private"

Vue.use(Router)

export default new Router({
  routes: [
    {
      path: '/',
      name: 'Sign In',
      component: Public
    },
    {
      path: '/signup',
      name: 'Sign Up',
      component: Public
    },
    {
      path: '/request-enterprise',
      name: 'Request Enterprise',
      component: Private
    }
  ]
})
