<template>
  <v-app>
    <v-app-bar
            app
            dark
    >
      <v-btn icon v-if="$route.name != 'Sign In'" @click="$router.go(-1)">
        <v-icon>mdi-arrow-left</v-icon>
      </v-btn>
      {{ $route.name }}
    </v-app-bar>
    <v-content>
      <v-container
              fluid
              fill-height
      >
        <router-view />
      </v-container>
    </v-content>
  </v-app>
</template>

<script>
import Public from "./views/Public";

export default {
  name: 'App',
  components: {
    Public
  },
  data: () => ({
    //
  }),
};
</script>
