<template>
    <v-flex class="SignUp">
        <v-layout justify-center>
            <v-flex xs12 sm10 md8 lg6>
                <v-text-field
                        class="my-1 mx-2"
                        ref="name"
                        label="Nombre"
                        required
                ></v-text-field>
                <v-text-field
                        class="my-1 mx-2"
                        ref="email"
                        label="Email"
                        required
                ></v-text-field>
                <v-text-field
                        class="my-1 mx-2"
                        ref="password"
                        label="Password"
                        type="password"
                        required
                ></v-text-field>
                <v-text-field
                        class="my-1 mx-2"
                        ref="phone"
                        label="Telefono"
                        required
                ></v-text-field>
                <v-text-field
                        class="my-1 mx-2"
                        ref="birthday"
                        label="Fecha de Nacimiento"
                        required
                ></v-text-field>
                <v-checkbox
                        ref="legal"
                        label="Condiciones de Uso"
                        required
                ></v-checkbox>
                <v-btn class="my-1 mx-2" block dark>Registrarse</v-btn>
            </v-flex>
        </v-layout>
    </v-flex>
</template>

<script>
    export default {
        name: 'SignUp',
        props: {
        },
    };
</script>
