<template>
    <v-flex class="SignIn">
        <v-layout justify-center>
            <v-flex xs12 sm10 md8 lg6>
                <v-text-field
                        class="my-1 mx-2"
                        ref="email"
                        label="Email"
                        required
                ></v-text-field>
                <v-text-field
                        class="my-1 mx-2"
                        ref="password"
                        label="Password"
                        type="password"
                        required
                ></v-text-field>
                <v-btn class="my-1 mx-2" block dark>Ingresar</v-btn>
                <v-btn class="my-1 mx-2" block dark>Usar Facebook</v-btn>
                <v-btn class="my-1 mx-2" block dark>User Linkedin</v-btn>
                <v-btn class="my-1 mx-2" block dark>Usar Google</v-btn>
                <v-btn class="my-1 mx-2" block dark :to="{ name: 'Sign Up' }">Registrarse</v-btn>
            </v-flex>
        </v-layout>
    </v-flex>
</template>

<script>
    export default {
        name: 'SignIn',
        props: {
        },
    };
</script>
