<template>
    <v-flex class="RequestEnterprise">
        <v-layout justify-center>
            <v-flex xs12 sm10 md8 lg6>
                <v-text-field
                        class="my-1 mx-2"
                        ref="razonsocial"
                        label="Razón Social"
                        required
                ></v-text-field>
                <v-text-field
                        class="my-1 mx-2"
                        ref="fantacyname"
                        label="Nombre de Fantasia"
                        required
                ></v-text-field>
                <v-text-field
                        class="my-1 mx-2"
                        ref="rut"
                        label="RUT"
                        required
                ></v-text-field>
                <v-text-field
                        class="my-1 mx-2"
                        ref="giro"
                        label="Giro"
                        required
                ></v-text-field>
                <v-text-field
                        class="my-1 mx-2"
                        ref="address"
                        label="Dirección"
                        required
                ></v-text-field>
                <v-text-field
                        class="my-1 mx-2"
                        ref="web"
                        label="Pagina Web"
                        required
                ></v-text-field>
                <v-text-field
                        class="my-1 mx-2"
                        ref="contact"
                        label="Persona de Contacto"
                        required
                ></v-text-field>
                <v-text-field
                        class="my-1 mx-2"
                        ref="email"
                        label="Email de Contacto"
                        required
                ></v-text-field>
                <v-text-field
                        class="my-1 mx-2"
                        ref="phone"
                        label="Telefono de Contacto"
                        required
                ></v-text-field>
                <v-btn class="my-1 mx-2" block dark>Enviar</v-btn>
            </v-flex>
        </v-layout>
    </v-flex>
</template>

<script>
    export default {
        name: 'RequestEnterprise',
        props: {
        },
    };
</script>
