<template>
  <v-flex>
    <RequestEnterprise />
  </v-flex>
</template>

<script>

import RequestEnterprise from "../components/RequestEnterprise/RequestEnterprise";
export default {
  name: 'Private',
  components: {
    RequestEnterprise
  },
};
</script>
